<template>
	<div class="mainBox">
		<div class="infoBox">
			<img src="~@/assets/images/games/icon.jpg" />
			<div class="infos">
				<div style="width: 100%;height: 3.875rem;">
					<div class="title">절망의 땅: 복수자<span>15+</span></div>
					<p class="desc">{{ $t('iosdown.rankNum') }}</p>
				</div>
				<div class="btns" v-if="isIOS" id="downBtn" @click="down">{{ $t('iosdown.btn1') }}</div>
				<div class="btns" v-else>{{ $t('iosdown.notIOS') }}</div>
			</div>
		</div>
		<div class="tagBox">
			<div class="van-hairline--top"></div>
			<div class="tags">
				<div class="items">
					<p class="s">{{ $t('iosdown.scoreNum') }}</p>
					<p class="l">4.8</p>
					<p class="s">
						<van-icon name="star" />
						<van-icon name="star" />
						<van-icon name="star" />
						<van-icon name="star" />
						<van-icon name="star" />
					</p>
				</div>
				<div class="items">
					<p class="s">{{ $t('iosdown.ranking') }}</p>
					<p class="l"><i class="ltag">#</i>3</p>
					<p class="s">{{ $t('iosdown.other') }}</p>
				</div>
				<div class="items">
					<p class="s">{{ $t('iosdown.age') }}</p>
					<p class="l">15+</p>
					<p class="s">{{ $t('iosdown.ageUnit') }}</p>
				</div>
			</div>
			
		</div>
		<div class="developBox">
			<div class="van-hairline--top"></div>
			<div class="ques" v-if="!showTips" @click="showTips = true">
				<p>
					{{ $t('iosdown.quesTips') }}
					<van-icon name="arrow-down" size="22" />
				</p>
				<ul>
					<li>{{ $t('iosdown.appQuit') }}</li>
					<li>{{ $t('iosdown.unInstall') }}</li>
					<li>{{ $t('iosdown.blackIcon') }}</li>
					<li>{{ $t('iosdown.developMode') }}</li>
				</ul>
			</div>
			<div class="quesCon" v-if="showTips">
				<div class="con">
					<p>{{ $t('iosdown.fixCon1') }}</p>
					<p>{{ $t('iosdown.fixCon2') }}</p>
					<p>{{ $t('iosdown.fixCon3') }}</p>
					<p>
					<!-- {{ $t('iosdown.fixCon4') }} -->
					<i18n-t keypath="iosdown.fixCon4" tag="false">
						<template v-slot:str>
							<strong>{{ $t('iosdown.fixCon4Str') }}</strong>
						</template>
					</i18n-t>
					</p>
					<p>
					<!-- {{ $t('iosdown.fixCon5') }} -->
					<i18n-t keypath="iosdown.fixCon5" tag="false">
						<template v-slot:str>
							<strong>{{ $t('iosdown.fixCon5Str') }}</strong>
						</template>
					</i18n-t>
					</p>
					<div class="btn" @click="fix">{{ $t('iosdown.fixBtn') }}</div>
				</div>
				<div class="con">
					<p>{{ $t('iosdown.developCon1') }}</p>
					<p>{{ $t('iosdown.developCon2') }}</p>
					<p>
					<!-- {{ $t('iosdown.developCon3') }} -->
					<i18n-t keypath="iosdown.developCon3" tag="false">
						<template v-slot:str>
							<strong>{{ $t('iosdown.developCon3Str') }}</strong>
						</template>
					</i18n-t>
					</p>
					<p>{{ $t('iosdown.developCon4') }}</p>
					<div class="btn" @click="showDevelop = true">{{ $t('iosdown.developBtn') }}</div>
				</div>
				<p class="arrow" @click="showTips = false">
					<van-icon name="arrow-up" size="22" />
				</p>
			</div>
		</div>
		<div class="imgsBox">
			<div class="imgs">
				<img @click="handleImgPreview(0)" src="~@/assets/images/games/1.jpg" />
				<img @click="handleImgPreview(1)" src="~@/assets/images/games/2.jpg" />
				<img @click="handleImgPreview(2)" src="~@/assets/images/games/3.jpg" />
				<img @click="handleImgPreview(3)" src="~@/assets/images/games/4.jpg" />
				<img @click="handleImgPreview(4)" src="~@/assets/images/games/5.jpg" />
			</div>
		</div>
		<div class="desBox">
			<div class="van-hairline--top"></div>
			<div class="dess">
				<p>돌파하는 전통적인 다크 MMO</p>
				<p>실시간 강력 전투 PK와 극한의 폭발적인 고화질 그래픽으로, 당신을 심연 같은 세계로 안내합니다!</p>
				<p></p>
				<p>서버를 초월한 마법 동맹 전쟁</p>
				<p>다양한 독특한 콘텐츠와 함께 위험과 도전으로 가득한 판타지 세계에서 자유로운 혈전의 쾌감을 만끽하세요!</p>
				<p></p>
				<p>압도적인 다크 무기, 화려하고 기괴한 코스튬, 그리고 각양각색의 악마 탈것</p>
				<p>당신과 함께 싸우며 이 잃어버린 대륙에서 통치력을 발휘하세요!</p>
				<p></p>
				<p>어둠의 세계에서 운명적인 친구를 만들고, 악마의 길을 함께할 동반자를 찾아보세요.</p>
				<p>부부 던전에서 손을 맞잡고 타락의 끝으로 나아가세요!</p>
				<p></p>
				<p>피와 열정이 얽히는 왕좌의 길</p>
				<p>어두운 운명은 당신의 발 아래에 있습니다. 용감히 앞으로 나아가 악마 왕의 전설을 완성하세요!</p>
				<p></p>
				<p>신비로운 NPC 퀘스트와 상호작용</p>
				<p>비교할 수 없는 전투 경험으로 매 순간 몰입하게 하며, 벗어날 수 없게 만듭니다!</p>
				<p></p>
				<p>이 어두운 세계에서 오직 강자만이 생존할 수 있습니다.</p>
				<p>끝없는 시련을 맞이할 준비가 되었나요?</p>
			</div>
		</div>
		<div class="commentBox">
			<div class="van-hairline--top"></div>
			<div class="titles">
				<span class="l">{{ $t('iosdown.comments') }}</span>
				<span class="btn">{{ $t('iosdown.suggest') }}</span>
			</div>
			<div class="cons">
				<div class="scores">
					<span>4.8</span>
					<p>{{ $t('iosdown.scoreFull') }}</p>
				</div>
				<div class="stats">
					<div class="lineStars">
						<div class="stars star05"></div>
						<div class="line per92"></div>
					</div>
					<div class="lineStars">
						<div class="stars star04"></div>
						<div class="line per1"></div>
					</div>
					<div class="lineStars">
						<div class="stars star03"></div>
						<div class="line per3"></div>
					</div>
					<div class="lineStars">
						<div class="stars star02"></div>
						<div class="line"></div>
					</div>
					<div class="lineStars">
						<div class="stars star01"></div>
						<div class="line"></div>
					</div>
					<p>{{ $t('iosdown.scoreNum') }}</p>
				</div>
			</div>
		</div>
		<div class="newsBox">
			<div class="van-hairline--top"></div>
			<div class="titles">
				<span class="l">{{ $t('iosdown.newFunc') }}</span>
			</div>
			<div class="cons news">
				<div>{{ $t('iosdown.version') }}</div>
				<div>2025-02-19 20:42:05</div>
			</div>
		</div>
		<div class="msgsBox">
			<div class="van-hairline--top"></div>
			<div class="titles">
				<span class="l">{{ $t('iosdown.info') }}</span>
			</div>
			<div class="cons msg">
				<div class="label">{{ $t('iosdown.size') }}</div>
				<div class="val">301 MB</div>
			</div>
			<div class="cons msg">
				<div class="label">{{ $t('iosdown.type') }}</div>
				<div class="val">{{ $t('iosdown.other') }}</div>
			</div>
			<div class="cons msg">
				<div class="label">{{ $t('iosdown.compatible') }}</div>
				<div class="val">{{ $t('iosdown.compatibleDes') }}</div>
			</div>
			<div class="cons msg">
				<div class="label">{{ $t('iosdown.language') }}</div>
				<div class="val">{{ $t('iosdown.korea') }}</div>
			</div>
			<div class="cons msg">
				<div class="label">{{ $t('iosdown.ageLevel') }}</div>
				<div class="val">15+</div>
			</div>
			<div class="cons msg">
				<div class="label">Copyright</div>
				<div class="val">© 2018 MobiFun Games Inc</div>
			</div>
			<div class="cons msg">
				<div class="label">{{ $t('iosdown.price') }}</div>
				<div class="val">{{ $t('iosdown.free') }}</div>
			</div>
			<div class="cons msg">
				<div class="label">{{ $t('iosdown.privacy') }}</div>
				<div class="val">✋</div>
			</div>
		</div>
		<div class="footerBox">
			<div class="van-hairline--top"></div>
			<div>
				<p>{{ $t('iosdown.disclaimer') }}：</p>
				<p>{{ $t('iosdown.disclaimerDes') }}</p>
			</div>
		</div>
		
		<van-overlay :show="showDevelop">
		  <div class="develops">
		    <img src="~@/assets/images/games/s0.png">
			<van-swipe :autoplay="3000" lazy-render @change="onChange">
			  <van-swipe-item v-for="image in images" :key="image">
			    <img :src="image" />
			  </van-swipe-item>
			</van-swipe>
			<div class="tips">
				<p class="title">{{ $t('iosdown.developTitle') }}（{{ Number(activeImg)+1 }}）</p>
				<p v-if="activeImg == 4">{{ $t('iosdown.developEnd') }}</p>
				<p v-else>{{ $t('iosdown.developNext') }}</p>
			</div>
			<p @click="showDevelop = false">{{ $t('iosdown.developOpen') }}</p>
		  </div>
		</van-overlay>
		
		<van-popup
		  v-model:show="showRes"
		  position="bottom"
		  :style="{ height: '30%' }"
		>
		<div class="resBox" v-html="resMsg"></div></van-popup>
		
		<van-image-preview v-model:show="showImg" :images="bigImages" @change="onBigChange">
		  <template v-slot:index>{{ activeBigImg + 1 }}</template>
		</van-image-preview>

	</div>
</template>
<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	const { proxy } = getCurrentInstance();
	const showTips = ref(false)
	const showDevelop = ref(false)
	const showRes = ref(false)
	const showImg = ref(false)
	const isIOS = ref(false)
	const activeImg = ref(0)
	const activeBigImg = ref(0)
	const resMsg = ref('')
	const images = ref([
		require('@/assets/images/games/s1.png'),
		require('@/assets/images/games/s2.png'),
		require('@/assets/images/games/s3.png'),
		require('@/assets/images/games/s4.png'),
		require('@/assets/images/games/s5.png')
	])
	const bigImages = ref([
		require('@/assets/images/games/1.jpg'),
		require('@/assets/images/games/2.jpg'),
		require('@/assets/images/games/3.jpg'),
		require('@/assets/images/games/4.jpg'),
		require('@/assets/images/games/5.jpg')
	])
	// 这里替换成你要下载的 app 对应的信息
	const appInfo = ref({
		bundleId : "com.qpmlios.test", appName : "", remark : ""
	})
	// 自定义一个渠道 key 放到 info.plist 中，供 App 内部代码读取，再在 LSApplicationQueriesSchemes 中新增一个 "scheme.zhipian.com" 的值
	const cusAppConfig = ref({
		infoPlistParam : {
			zpLang: "ko",
		  agent : "杭州代理渠道",
		  LSApplicationQueriesSchemes : ["scheme.zhipian.com"]
		}
	})
	onMounted(() => {
		var yourMerchantName = "18773194015";   // 这里替换诚你的纸片内测账户名
		var yourMerchantCode = "46A53FE873AA2C76934677BF5F9D819F";    // 这里替换成纸片内测管理后台-SDK对接-【接入参数】SecretKey
			// genguAPI插件初始化，必须在页面打开时就调用
		gengu.CreatGenGu({
		  merchantName: yourMerchantName,
		  merchantCode: yourMerchantCode
		});
		setTimeout(() => {
			down()
		},500)
	})
	const onChange = (index) => {
		activeImg.value = index
	};
	const onBigChange = (index) => {
		activeBigImg.value = index
	};
	const handleImgPreview = (val) => {
		activeBigImg.value = val
		showImg.value = true
	}
	// 开始签名
	const down = () => {
		// 判断是否是在手机并且是在Safari打开
		if(genguUtil.oauserFun().isSafari && genguUtil.oauserFun().isMobile) {
			isIOS.value = true
			gengu.quickSign(appInfo.value, downCall, cusAppConfig.value);
			console.log(cusAppConfig.value)
		} else {
			isIOS.value = false
			proxy.$toast(proxy.$t('iosdown.SafariTips'))
		}
	}
	// 签名回调
	const downCall = (type, step, extraInfo) => {
	  if (type == "findAppError") {
		proxy.$toast(proxy.$t('iosdown.findAppError'))
	  } else if (type == "networkError") {
	    proxy.$toast(proxy.$t('iosdown.networkError'));
	  } else if (type == "ParamsError") {
	    proxy.$toast(proxy.$t('iosdown.ParamsError'));
	  } else {
		showRes.value = true
	    resMsg.value = `
	    <div>${proxy.$t('iosdown.signStatus')}：${type}</div>
	    <div>${proxy.$t('iosdown.signStep')}：${step}</div>`
	
	    if (type === 'success') {
	      document.getElementById("downBtn").innerHTML = proxy.$t('iosdown.btn2');
	    } else if (type === 'timeout') {
	      document.getElementById("downBtn").innerHTML = proxy.$t('iosdown.signStep');
	    } else if (type === 'delaying') {
	      document.getElementById("downBtn").innerHTML = proxy.$t('iosdown.btn3');
			showRes.value = true
			resMsg.value = `
					<div>${proxy.$t('iosdown.signStatus')}：${type}</div>
					<div>${proxy.$t('iosdown.signStep')}：${step}</div>
					<div>${proxy.$t('iosdown.btn3')}，${$t('iosdown.failTips')}</div>
					<div>${proxy.$t('iosdown.down1')}：${extraInfo.tempUrl}</div>
					<div>${proxy.$t('iosdown.down2')}：${extraInfo.cloudGameUrl}</div>`
	    }
	  }
	}
	const fix = () => {
		if (genguUtil.oauserFun().isSafari && genguUtil.oauserFun().isMobile) {
			gengu.quickFixApp(appInfo.value, fixAppCallback);
		} else {
			proxy.$toast(proxy.$t('iosdown.SafariTips'))
		}
	}
	const fixAppCallback = (status) => {
		// 当前修复状态：
		// findAppError - 无法找到需要修复的 App
		// 1 - 超过修复次数限制；
		// 2 - 修复成功（后续提示用户删除桌面上的老包，再次下载即可，由你们根据业务自行决定后续逻辑）
		// 3 - 无需修复，代表包是没问题的
		// 4 - 修复中，之后会再次回调该 callback，直到不为 4 为止
		proxy.$toast(proxy.$t('iosdown.fixStatus') + status);
	}
	
</script>

<style lang="less" scoped>
	.mainBox{
		background: #fff;max-width: 46.875rem;margin: 0 auto;
		.infoBox{
			display: flex;align-items: flex-start;justify-content: flex-start;
			padding: 1.125rem 1.25rem;
			img{
				width: 6.875rem;height: 6.875rem;margin-right: 1rem;border: 1px solid #E4E3DF;border-radius: 1.5rem;
			}
			.infos{
				height: 6.875rem;
				.title{
					font-size: 1.25rem;font-weight: 500;display: flex;align-items: center;
					span{
						font-size: 0.75rem;font-weight: 400;color: #8e8e93;padding: 0 0.125rem 0.0625rem;box-sizing: border-box;
						display: inline-block;border: 1px solid #8e8e93;border-radius: 0.1875rem;margin-left: 0.3125rem;
					}
				}
				.desc{
					font-size: 0.875rem;color: #A6A6A6;
				}
				.btns{
					background: #2F7BFF;color: #fff;padding: 0.25rem 0.75rem;box-sizing: border-box;font-size: 0.9375rem;
					border-radius: 0.75rem;height: 3rem;line-height: 1.25rem;
				}
			}
		}
		.tagBox{
			padding: 0 1.25rem;
			.tags{
				display: flex;align-items: center;justify-content: space-between;padding: 0.75rem 0;
				.items{
					width: calc(100% / 3);text-align: center;color: #8D8D8D;line-height: 1.625rem;position: relative;
					.s{
						font-size: 0.75rem;color: #AEAEAE;
					}
					.l{
						font-size: 1.375rem;font-weight: 500;margin-top: 0.125rem;position: relative;
						.ltag{
							font-size: 0.875rem;font-style: normal;position: absolute;top: -0.1875rem;left: calc(50% - 1rem);
						}
					}
				}
				.items + .items{
					&::after{
						position: absolute;content: '';width: 1px;height: 50%;background: #E7E7E7;
						left: 0;top: 25%;
					}
				}
			}
		}
		.developBox{
			padding: 0 1.25rem;
			.ques{
				padding-top: 0.625rem;
				p{
					line-height: 1.5rem;font-size: 0.875rem;margin-bottom: 0.3125rem;color: #2F7BFF;
					/deep/.van-icon{
						float: right;
					}
				}
				ul{
					display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;
					li{
						padding: 0.125rem 0.3125rem;background: #f76060;color: #fff;margin-bottom: 0.125rem;
						font-size: 0.75rem;border-radius: 0.3125rem;
					}
					li + li{
						margin-left: 0.3125rem;
					}
				}
			}
			.quesCon{
				color: #232327;
				.con{
					background: #F2F1F5;border-radius: 0.625rem;width: 100%;
					padding: 0.625rem 0.75rem;font-size: 0.875rem;box-sizing: border-box;
					p + p{
						margin-top: 0.625rem;
					}
					.btn{
						background: #2F7BFF;color: #fff;display: inline-block;padding: 0.3125rem 1rem;border-radius: 0.9375rem;
						margin: 0.3125rem auto;margin-left: 50%;transform: translate(-50%,0);white-space: nowrap;
					}
				}
				.con + .con{
					margin-top: 0.625rem;
				}
				p.arrow{
					margin: 0.3125rem 0;height: 1.375rem;
					/deep/.van-icon{
						float: right;color: #2F7BFF;
					}
				}
			}
		}
		.imgsBox{
			padding: 1.25rem;box-sizing: border-box;
			.imgs{
				width: 100%;overflow-x: scroll;
				display: flex;align-items: center;justify-content: flex-start;
				img{
					width: 92%;border-radius: 1.25rem;
				}
				img + img{
					margin-left: 0.75rem;
				}
			}
		}
		.desBox{
			padding: 0 1.25rem;
			.dess{
				padding:  1.25rem 0;line-height: 1.375rem;
				p{
					min-height: 1.375rem;
				}
			}
		}
		.commentBox,.newsBox,.msgsBox{
			padding: 0 1.25rem 1.25rem;
			.titles{
				padding-top: 0.625rem;display: flex;align-items: center;justify-content: space-between;
				.l{
					font-size: 1.125rem;font-weight: 500;color: #030303;
				}
				.btn{
					font-size: 1rem;color: #3F72F7;display: inline-block;
				}
			}
			.cons{
				display: flex;align-items: center;justify-content: space-between;
				&.news{
					color: #929292;font-size: 0.875rem;margin-top: 0.3125rem;
				}
				.scores{
					span{
						font-size: 3.125rem;font-weight: bold;color: #4A4A4A;
					}
					p{
						text-align: center;color: #888888;font-size: 0.75rem;
					}
				}
				.stats{
					width: calc(100% - 7.5rem);padding-top: 0.75rem;
					.lineStars{
						width: 100%;height: 0.5rem;display: flex;align-items: center;justify-content: flex-end;
						.stars{
							height: 100%;margin-right: 0.625rem;
							background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTM4IDIzIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMS40MTQiPjxwYXRoIGQ9Ik00MC4yNTIgMGwtMy41IDloLThsNi42MjUgNC44NzVMMzIuNzUyIDIzbDcuNS02IDcuNSA2LTIuNjI1LTkuMTI1TDUxLjc1MiA5aC04bC0zLjUtOXpNMTEuNSAwTDggOUgwbDYuNjI1IDQuODc1TDQgMjNsNy41LTYgNy41IDYtMi42MjUtOS4xMjVMMjMgOWgtOGwtMy41LTl6bTExNS4wMDggMGwtMy41IDloLThsNi42MjUgNC44NzVMMTE5LjAwOCAyM2w3LjUtNiA3LjUgNi0yLjYyNS05LjEyNUwxMzguMDA4IDloLThsLTMuNS05ek05Ny43NTYgMGwtMy41IDloLThsNi42MjUgNC44NzVMOTAuMjU2IDIzbDcuNS02IDcuNSA2LTIuNjI1LTkuMTI1TDEwOS4yNTYgOWgtOGwtMy41LTl6TTY5LjAwNCAwbC0zLjUgOWgtOGw2LjYyNSA0Ljg3NUw2MS41MDQgMjNsNy41LTYgNy41IDYtMi42MjUtOS4xMjVMODAuNTA0IDloLThsLTMuNS05eiIgZmlsbD0iIzhlOGU5MyIvPjwvc3ZnPg==');
							background-size: 2.5rem;
							background-repeat: no-repeat;
							&.star05{
								width: 2.5rem;
							}
							&.star04{
								width: 2rem;
							}
							&.star03{
								width: 1.5rem;
							}
							&.star02{
								width: 1rem;
							}
							&.star01{
								width: 0.5rem;
							}
						}
						.line{
							width: calc(100% - 3.75rem);height: 0.125rem;background: #E8E8E8;border-radius: 0.125rem;
							&.per92{
								position: relative;
								&::after{
									position: absolute;content: '';left: 0;top: 0;border-radius: 0.125rem;
									width: 96%;height: 100%;background: #7C7C7C;
								}
							}
							&.per3{
								position: relative;
								&::after{
									position: absolute;content: '';left: 0;top: 0;border-radius: 0.125rem;
									width: 3%;height: 100%;background: #7C7C7C;
								}
							}
							&.per1{
								position: relative;
								&::after{
									position: absolute;content: '';left: 0;top: 0;border-radius: 0.125rem;
									width: 1%;height: 100%;background: #7C7C7C;
								}
							}
						}
					}
					p{
						text-align: right;color: #888888;font-size: 0.75rem;padding-top: 0.375rem;
					}
				}
				
			}
		}
		.msgsBox{
			.msg{
				padding: 0.625rem 0;font-size: 0.875rem;
				.label{
					width: 6.25rem;flex-shrink: 0;color: #898889;
				}
			}
			.msg + .msg{
				border-top: 1px solid #ebedf0;
			}
		}
		.footerBox{
			background: #eee;color: #a9a9a9;font-size: 0.6875rem;
			padding: 0.625rem;
		}
		.develops{
			width: 90%;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);
			/deep/.van-swipe-item{
				font-size: 0;
			}
			.tips{
				width: 100%;background: #fff;border-radius: 0 0 1.25rem 1.25rem;padding: 1rem 0;
				p{
					color: #999;text-decoration: none;line-height: 1.5rem;font-size: 0.875rem;
					&.title{
						font-size: 1.125rem;font-weight: 500;color: red;
					}
				}
			}
			img{
				max-width: 100%;
			}
			p{
				text-align: center;color: #fff;text-decoration: underline;line-height: 2.5rem;
			}
		}
		.resBox{
			padding: 0.75rem 1rem;box-sizing: border-box;line-height: 1.5rem;
		}
	}
</style>